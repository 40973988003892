<template>
  <a-card class="card" :bordered="false">
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row>
        <a-col span="6">
          <a-form-model-item label="申请日期">
            <a-range-picker @change="onDateChange"/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-button @click="searchInfo" class="ant-btn-query margin-right10">查询</a-button>
        <a-button type="primary" @click="newRecord" class="margin-right10">新增</a-button>
      </a-row>
    </a-form-model>
    <a-table
      rowKey="id"
      size="middle"
      :scroll="{ x: true }"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="changeTable"
    >
      <div slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </div>
      <div slot="action" slot-scope="text, record">
        <a v-if="record.status <= 4" @click="delRecord(record)">删除</a>
      </div>
    </a-table>
    <a-modal v-model="visible" @ok="handleSave" @cancel="handleCancel" title="开票申请" :width="980">
      <a-form-model
        ref="ruleForm"
        :model="applyInvoiceForm"
        :rules="rules"
      >
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="委托单位/客户名称" prop="customer_name">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo2"
                :filter-option="false"
                :not-found-content="fetching2 ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching2" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerInfoOps2" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
              <a-form-model-item label="发票类型" prop="invoice_type">
                <a-select v-model="applyInvoiceForm.invoice_type">
                  <a-select-option :value="item.value" v-for="item in invoiceTypeList" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          <a-col :span="24">
            <a-form-model-item label="业务备注">
              <a-input v-model="applyInvoiceForm.apply_remark"></a-input>
            </a-form-model-item>
          </a-col>
          <a-divider orientation="left">开票信息</a-divider>
          <a-col :span="8">
            <a-form-model-item label="纳税人识别号" prop="tax_no">
              <a-input v-model="invoiceBuyerInfo.buyer_tax_no" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="地址">
              <a-input v-model="invoiceBuyerInfo.buyer_address_phone" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="电话">
              <a-input v-model="invoiceBuyerInfo.buyer_telphone" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="开户银行">
              <a-input v-model="invoiceBuyerInfo.buyer_bank_account" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="银行卡号">
              <a-input v-model="invoiceBuyerInfo.buyer_account">
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <edit-table
              :columns="invoiceSubjectColumns"
              :sourceData="invoiceSubjectData"
              :scroll="true"
              :operate="{ add: true, del: true, alldel: false }"
              @getTableDate="getInvoiceSubjectTableDate"></edit-table>
          </a-col>
          <a-col :span="24">
            <span style="font-weight:700;color:red">含税总额：{{ this.totalInvoiceAmount }}</span>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="开票备注">
              <a-input v-model="applyInvoiceForm.invoice_remark"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>
<script>
import { EditTable } from '@/components'
import { getCustomerCopy } from '@/api/common'
import { listSimpleInvoiceRecord, applySimpleInvoice, deleteSimpleApply,
  queryInvoiceBuyerByCustomerId, listSecondInvoiceSubject } from '@/api/fms'
export default {
  components: {
    EditTable
  },
  data() {
    return {
      labelCol: { span: 4 },
      labelModel: { span: 8 },
      wrapperModel: { span: 12 },
      wrapperCol: { span: 14 },
      form: {},
      invoiceTypeList: [
        {
          value: 1, name: '专用发票'
        },
        {
          value: 2, name: '普通发票'
        }
      ],
      applyInvoiceForm: {},
      totalInvoiceAmount: 0,
      rules: {
        department: [{ required: true, message: '请选择部门', trigger: 'blur' }],
        month: [{ required: true, message: '请选择月份', trigger: 'blur' }]
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '客户名称',
          dataIndex: 'customer_name'
        },
        {
          title: '业务备注',
          dataIndex: 'apply_remark'
        },
        {
          title: '开票备注',
          dataIndex: 'invoice_remark'
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          customRender: text => {
            return text >= 0 ? this.GLOBAL.feeStatusMaps[text].name : ''
          }
        },
        {
          title: '申请人',
          dataIndex: 'applicant_name'
        },
        {
          title: '申请时间',
          dataIndex: 'apply_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      selectedRowKeys: [],
      visible: false,
      modelTitle: '新增',
      modelKey: 'a',
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      moduleName: 'direct_invoice',
      secondInvoiceSubjectList: [],
      invoiceSubjectData: [],
      invoiceSubjectColumns: [
        {
          title: `货物或应税劳务,服务名称`,
          dataIndex: `goods_name`,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  let subject = null
                  debugger
                  for (const item of this.secondInvoiceSubjectList) {
                    if (item.id === val) {
                      subject = item
                    }
                  }
                  this.invoiceSubjectData[index]['subject_id'] = subject.id
                  this.invoiceSubjectData[index]['goods_name'] = subject.subject_name
                  this.invoiceSubjectData[index]['goods_code'] = subject.subject_code
                  this.invoiceSubjectData[index]['goods_specification'] = subject.subject_spec
                  this.invoiceSubjectData[index]['goods_unit'] = subject.subject_unit
                  this.invoiceSubjectData[index]['goods_tax_rate'] = subject.tax_rate
                  if (subject.subject_name === '运输费' && subject.tax_rate === 0.09) {
                    if (!this.applyInvoiceForm.invoice_remark) {
                      this.applyInvoiceForm.invoice_remark = '转售运输服务'
                    }
                  }
                }}
              >
                {this.secondInvoiceSubjectList.map(op => {
                  var opName = op.subject_name
                  if (op.subject_spec) {
                    opName = opName + '/' + op.subject_spec
                  }
                  opName = opName + '/' + op.tax_rate
                  return <a-select-option key={op.id}>{opName}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `金额`,
          dataIndex: `goods_total_price`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_price'] = val
                  if (this.invoiceSubjectData[index]['goods_tax_rate']) {
                    var taxRate = this.invoiceSubjectData[index]['goods_tax_rate']
                    this.invoiceSubjectData[index]['goods_total_tax'] = (val / (1 + taxRate) * taxRate).toFixed(2)
                  } else {
                    this.invoiceSubjectData[index]['goods_total_tax'] = 0
                  }
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `税率`,
          dataIndex: `goods_tax_rate`,
          width: 100
        },
        {
          title: `税额`,
          dataIndex: `goods_total_tax`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_tax'] = val
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        }
      ],
      lastFetchId2: 0,
      fetching2: false,
      customerInfoOps2: [],
      customerInfo2: undefined,
      invoiceBuyerInfo: {
        id: null,
        buyer_id: null,
        buyer_name: null,
        buyer_tax_no: null,
        buyer_address_phone: null,
        buyer_telphone: null,
        buyer_bank_account: null,
        buyer_account: null
      }
    }
  },
  created() {
    this.searchInfo()
    listSecondInvoiceSubject({ department: 5 }).then(v => {
      this.secondInvoiceSubjectList = v
    })
  },
  methods: {
    onQueryMonthChange(value) {
      this.form.month = value.format('YYYY-MM')
    },
    onFormMonthChange(value) {
      this.applyInvoiceForm.month = value.format('YYYY-MM')
    },
    calcTotalPrice(againFlag) {
      this.totalInvoiceAmount = 0
      this.invoiceSubjectData.forEach(v => {
        if (againFlag) {
          if (v.goods_quantity && v.goods_price && v.goods_tax_rate) {
            v.goods_total_price = v.goods_quantity * v.goods_price
            v.goods_total_tax = (v.goods_total_price / (1 + v.goods_tax_rate) * v.goods_tax_rate).toFixed(2)
          }
        }
        if (v.goods_total_price) {
          this.totalInvoiceAmount = this.totalInvoiceAmount + v.goods_total_price
        }
      })
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.form.apply_from_time = startDate
        this.form.apply_to_time = endDate
      } else {
        this.form.apply_from_time = null
        this.form.apply_to_time = null
      }
    },
    getInvoiceSubjectTableDate(data) {
      this.invoiceSubjectData = data
    },
    getInfo(param) {
      listSimpleInvoiceRecord({
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        ...param
      }).then(res => {
        this.data = res.list
        this.pagination.total = res.total
      })
    },
    searchInfo() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getInfo(Object.assign({}, this.form))
    },
    changeTable(pagination) {
      this.pagination = pagination
      this.getInfo(Object.assign({}, this.form))
    },
    getDepartmentCustomer(value) {
      this.lastFetchId2 += 1;
      const fetchId2 = this.lastFetchId2;
      this.customerInfoOps2 = [];
      this.fetching2 = true;
      getCustomerCopy({
        customerName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId2 !== this.lastFetchId2) {
          return;
        }
        this.customerInfoOps2 = res;
        this.fetching2 = false;
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo2 = value;
      this.applyInvoiceForm.customer_id = value.key
      queryInvoiceBuyerByCustomerId({ 'buyer_id': value.key }).then(res => {
        console.info(res)
        if (res) {
          this.invoiceBuyerInfo.id = res.id;
          this.invoiceBuyerInfo.buyer_id = res.buyer_id;
          this.invoiceBuyerInfo.buyer_name = res.buyer_name;
          this.invoiceBuyerInfo.buyer_tax_no = res.buyer_tax_no;
          this.invoiceBuyerInfo.buyer_address_phone = res.buyer_address_phone;
          this.invoiceBuyerInfo.buyer_telphone = res.buyer_telphone;
          this.invoiceBuyerInfo.buyer_bank_account = res.buyer_bank_account;
          this.invoiceBuyerInfo.buyer_account = res.buyer_account;
        } else {
          this.applyInvoiceForm.customer_name = value !== undefined ? value.label : null;
          this.customerInfoOps2.forEach(item => {
            if (item.value === value.key) {
              this.invoiceBuyerInfo.buyer_id = value.key
              this.invoiceBuyerInfo.buyer_name = item.name;
              this.invoiceBuyerInfo.buyer_tax_no = item.taxNum;
              this.invoiceBuyerInfo.buyer_address_phone = item.street;
              this.invoiceBuyerInfo.buyer_telphone = item.phone;
              this.invoiceBuyerInfo.buyer_account = item.accountInfo[0].account;
              this.invoiceBuyerInfo.buyer_bank_account = item.accountInfo[0].bank_name;
            }
          });
        }
        this.customerInfoOps2 = [];
          this.fetching2 = false;
      })
    },
    newRecord() {
      this.modelTitle = '新增'
      this.modelKey = 'a'
      this.visible = true
      this.applyInvoiceForm = {}
    },
    delRecord(record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          deleteSimpleApply({ id: record.id }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.searchInfo()
          })
        }
      })
    },
    handleSave() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.applyInvoiceForm.invoice_subject_data = this.invoiceSubjectData
          this.applyInvoiceForm.invoice_buyer_info = this.invoiceBuyerInfo
          applySimpleInvoice(this.applyInvoiceForm).then(res => {
              if (res) {
                this.$message.success('申请成功!')
                const param = Object.assign({}, this.form)
                param.page = this.pagination.current
                param.pageSize = this.pagination.pageSize
                this.getInfo(param)
              } else {
                this.$message.error('申请失败!')
              }
            })
          this.visible = false
          this.handleCancel()
        }
      })
    },
    handleCancel() {
      this.applyInvoiceForm = {}
      this.invoiceBuyerInfo = {}
      this.invoiceSubjectData = []
      this.visible = false
    }
  }
}
</script>
<style scoped>
.ant-table td {
  white-space: nowrap;
}
.margin-right10 {
  margin-right: 10px;
}
.ant-form-item {
  margin-bottom: 4px;
}
</style>
